import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CommonBannerModule,
  CommonMaterialModule,
  HttpXsrfCookieExtractor,
  HttpXsrfInterceptor,
  HttpXsrfTokenExtractor,
  XSRF_COOKIE_NAME,
  XSRF_HEADER_NAME,
} from '@dmv/common';
import { PublicCommonModule } from '@dmv/public/common';
import { API_CONFIGURATION } from '@dmv/public/shared/http';
import { AppState, AuthEffects, CountyEffects, ModalEffects, NavigationEffects, initialState, reducers } from '@dmv/public/shared/store';
import { SharedUiModule } from '@dmv/shared/ui';
import { AUTHENTICATION_CONFIGURATION, SharedUtilsAuthenticationModule } from '@dmv/shared/utils-authentication';
import { EventsModule } from '@libs/events';
import { DEFAULT_FEATURE_FLAGS, DefaultFeatureFlags, FEATURE_FLAG_CONFIGURATION, FeatureFlagModule } from '@libs/feature-flag';
import { EffectsModule } from '@ngrx/effects';
import { RootStoreConfig, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OktaAuthModule } from '@okta/okta-angular';
import { SharedModule } from '../app/shared/shared.module';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BarcodeComponent } from './barcode/barcode.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { GeneralFlowServiceService } from './services/general-flow-service.service';
import { HttpRequestInterceptor } from './services/http-request.interceptor';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, NotFoundComponent, BarcodeComponent],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonMaterialModule,
    PublicCommonModule,
    OktaAuthModule,
    AppRoutingModule,
    FormsModule,
    SharedModule,
    CommonBannerModule,
    EventsModule.useGoogleTagManager(environment.eventsConfiguration.googleTagManager),
    FeatureFlagModule.useLaunchDarkly(),
    SharedUtilsAuthenticationModule.useOkta(environment.authenticationConfiguration.okta),
    EffectsModule.forRoot([AuthEffects, CountyEffects, NavigationEffects, ModalEffects]),
    StoreModule.forRoot(reducers, <RootStoreConfig<AppState>>initialState),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      maxAge: 25,
    }),
    SharedUiModule,
  ],
  providers: [
    GeneralFlowServiceService,
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor },
    { provide: XSRF_COOKIE_NAME, useValue: 'XSRF-TOKEN' },
    { provide: XSRF_HEADER_NAME, useValue: 'X-XSRF-TOKEN' },
    { provide: HttpXsrfTokenExtractor, useClass: HttpXsrfCookieExtractor },
    { provide: FEATURE_FLAG_CONFIGURATION, useValue: environment.featureFlagConfiguration },
    { provide: DEFAULT_FEATURE_FLAGS, useValue: DefaultFeatureFlags },
    { provide: AUTHENTICATION_CONFIGURATION, useValue: environment.authenticationConfiguration },
    { provide: API_CONFIGURATION, useValue: { baseUrl: environment.apiUrl } },
    Title,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
