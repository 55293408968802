/* eslint-disable */
/**
 * Adapted from:
 * https://github.com/angular/angular/blob/f8096d499324cf0961f092944bbaedd05364eea1/packages/common/http/src/xsrf.ts
 */
import { DOCUMENT, ɵparseCookieValue as parseCookieValue } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';

export const XSRF_COOKIE_NAME = new InjectionToken<string>('XSRF_COOKIE_NAME');
export const XSRF_HEADER_NAME = new InjectionToken<string>('XSRF_HEADER_NAME');

export abstract class HttpXsrfTokenExtractor {
  abstract getToken(): string | null;
}

@Injectable()
export class HttpXsrfCookieExtractor implements HttpXsrfTokenExtractor {
  private lastCookieString = '';
  private lastToken: string | null = null;
  public parseCount = 0;

  constructor(
    @Inject(DOCUMENT) private doc: any,
    @Inject(PLATFORM_ID) private platform: Object,
    @Inject(XSRF_COOKIE_NAME) private cookieName: string,
  ) {}

  getToken(): string | null {
    if (this.platform === 'server') {
      return null;
    }
    const cookieString = this.doc.cookie || '';
    if (cookieString !== this.lastCookieString) {
      this.parseCount++;
      this.lastToken = parseCookieValue(cookieString, this.cookieName);
      this.lastCookieString = cookieString;
    }
    return this.lastToken;
  }
}

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {
  constructor(private tokenService: HttpXsrfTokenExtractor, @Inject(XSRF_HEADER_NAME) private headerName: string) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const lcUrl = req.url.toLowerCase();
    // Skip non-mutating requests which don't require a token
    if (req.method === 'GET' || req.method === 'HEAD') {
      return next.handle(req);
    }
    const token = this.tokenService.getToken();

    if (token !== null && !req.headers.has(this.headerName)) {
      req = req.clone({ headers: req.headers.set(this.headerName, token) });
    }
    return next.handle(req);
  }
}
